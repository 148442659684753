import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./main.css";

const swiper = new Swiper(".swiper", {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      const sliderImages = document.querySelectorAll(
        ".swiper-slide .featured-image"
      );
      let sliderImgURL = sliderImages[index].getAttribute(
        "data-featured-image"
      );

      if (!sliderImgURL) {
        sliderImgURL =
          "/wp-content/themes/cmic/assets/images/homepage/disc.png";
      }

      return `<span class="dot swiper-pagination-bullet">
      <img src="${sliderImgURL}" alt="" />
      </span>`;
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const popupElement = document.querySelector(".team-popup");
const teamMember = document.querySelectorAll(".team-member");
const closeButton = document.querySelector(".close-btn");

if (popupElement) {
  closeButton.addEventListener("click", function (ev) {
    ev.preventDefault();
    popupElement.classList.add("hidden");
    popupElement.classList.remove("flex");
  });

  popupElement.addEventListener("click", function (ev) {
    //check if clicked in shadow area
  });
}

if (teamMember) {
  teamMember.forEach((member) => {
    member.addEventListener("click", function (ev) {
      let openMemberinBackpage =
        member.getAttribute("data-open-backpage") === "true";

      console.log(typeof openMemberinBackpage);

      if (!openMemberinBackpage) {
        ev.preventDefault();

        const name = member.getAttribute("data-name");
        const title = member.getAttribute("data-title");
        const content = member.getAttribute("data-content");
        const featImg = member.getAttribute("data-feat-img");

        document.querySelector(".popup-name").textContent =
          decodeURIComponent(name);
        document.querySelector(".popup-title").textContent =
          decodeURIComponent(title);
        document.querySelector(".popup-description").innerHTML = content;

        if (featImg) {
          document.querySelector(".featured-team").style.display = "block";
          document
            .querySelector(".featured-team img")
            .setAttribute("src", featImg);
        } else {
          document.querySelector(".featured-team").style.display = "none";
        }

        popupElement.classList.remove("hidden");
        popupElement.classList.add("flex");
      }
    });
  });
}

document.querySelector(".nav-toggle").addEventListener("click", function (ev) {
  document.querySelector(".nav-toggle").classList.toggle("active");
  document.querySelector(".navigation").classList.toggle("hidden");
});

/* gallery slider */

const galleries = document.querySelectorAll(".wp-block-gallery");

if (galleries) {
  //create modal
  const modal = document.createElement("div");
  modal.classList.add("gallery-modal");

  const closeButton = document.createElement("a");
  closeButton.innerHTML = "&#x2715;";
  closeButton.classList.add("close-button");

  closeButton.addEventListener("click", (ev) => {
    ev.preventDefault();
    modal.classList.remove("active");
    document.body.classList.remove("no-doc-overflow");
  });

  const modalImg = document.createElement("img");
  modalImg.classList.add("modal-image");

  modal.append(closeButton);
  modal.append(modalImg);

  document.body.append(modal);
}

galleries.forEach((gallery, i) => {
  gallery.className = "";
  gallery.classList.add("swiper-wrapper");

  for (let i = 0; i < gallery.children.length; i++) {
    gallery.children[i].classList.add("swiper-slide");
    gallery.children[i].addEventListener("click", (ev) => {
      ev.preventDefault();
      document.querySelector(".gallery-modal")?.classList.add("active");
      document.body.classList.add("no-doc-overflow");

      const innerImage = gallery.children[i].querySelector("img");

      document.querySelector(".modal-image").src = innerImage.src;
      document.querySelector(".gallery-modal")?.append(modalImg);
    });
  }

  const mainSwiperDiv = document.createElement("div");
  mainSwiperDiv.classList.add("gallery-swiper-" + i);
  mainSwiperDiv.classList.add("gallery-swiper");

  document.querySelector("main").insertBefore(mainSwiperDiv, gallery);
  mainSwiperDiv.appendChild(gallery);

  const pagination = document.createElement("div");
  pagination.classList.add("gallery-swiper-pagination");
  pagination.classList.add("gallery-swiper-pagination-" + i);
  mainSwiperDiv.appendChild(pagination);

  const gallerySwiper = new Swiper(".gallery-swiper-" + i, {
    // configure Swiper to use modules
    modules: [Pagination],
    slidesPerView: 3,
    spaceBetween: 40,
    centeredSlides: true,
    initialSlide: 1,
    breakpoints: {
      320: {
        slidesPerView: 1,
        paceBetween: 10,
      }, 
      768: {
        slidesPerView: 2,
        paceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        paceBetween: 30,
      },
    },

    pagination: {
      el: ".gallery-swiper-pagination-" + i,
      clickable: true,
      
    },
  });
});

let scrollPosition = window.scrollY;
let footerHeight = document
  .querySelector("footer")
  .getBoundingClientRect().height;
let footerPosition = document.querySelector("footer").offsetTop;
let floatingButton = document.querySelector(".floating-button");

window.addEventListener("scroll", function () {
  scrollPosition = window.scrollY;
  footerHeight = document
    .querySelector("footer")
    .getBoundingClientRect().height;
  footerPosition = document.querySelector("footer").offsetTop;
  floatingButton = document.querySelector(".floating-button");

  if (scrollPosition + footerHeight * 2 > footerPosition) {
    floatingButton.style.display = "none";
  } else {
    floatingButton.style.display = "block";
  }
});
